import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useNavbar } from "../../../hooks/use-navbar";
import { LeftNavbarSection } from "./LeftNavbarSection";
import CustomizedScrollbar from "../../Custom/CustomizedScrollbar";
import packageJson from "../../../../package.json";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import { useStateContext } from "../../../contexts/auth-context";
import { hasPermission } from "../../../utils/Global";

const LeftNavbar = () => {
  const theme = useTheme();
  const navbar = useNavbar();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    state: { permissions },
  } = useStateContext();

  const filteredSections = useMemo(() => {
    const sections = [
      {
        title: t("contact.index"),
        items: [
          {
            title: t("contact.index"),
            path: "/contact",
            icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
            permissionKey: "contact",
            children: [
              {
                title: t("contact.contact_type.customer"),
                path: "/contact/customer",
                permissionKey: "customer",
              },
              {
                title: t("contact.contact_type.vendor"),
                path: "/contact/vendor",
                permissionKey: "vendor",
              },
            ],
          },
        ],
      },
      {
        title: t("inventory.index"),
        items: [
          {
            title: t("inventory.index"),
            path: "/inventory",
            icon: <Inventory2OutlinedIcon fontSize="small" />,
            permissionKey: "inventory",
            children: [
              {
                title: t("inventory.items.index"),
                path: "/inventory/items",
                permissionKey: "item",
              },
              {
                title: t("inventory.goods_receive.index"),
                path: "/inventory/goods-receive",
                permissionKey: "goods_receive",
              },
              {
                title: t("inventory.goods_return.index"),
                path: "/inventory/goods-return",
                permissionKey: "goods_return",
              },
              {
                title: t("inventory.goods_issue.index"),
                path: "/inventory/goods-issue",
                permissionKey: "goods_issue",
              },
              {
                title: t("inventory.goods_transfer.index"),
                path: "/inventory/goods-transfer",
                permissionKey: "goods_transfer",
              },
              {
                title: t("inventory.goods_adjustment.index"),
                path: "/inventory/goods-adjustment",
                permissionKey: "goods_adjust",
              },
              {
                title: t("inventory.price_list.index"),
                path: "/inventory/price-list",
                permissionKey: "price_list",
              },
              {
                title: t("inventory.report"),
                path: "/inventory/report",
                permissionKey: "inventory_report",
              },
            ],
          },
        ],
      },
      {
        title: t("sales.index"),
        items: [
          {
            title: t("sales.index"),
            path: "/sales",
            icon: <ControlPointOutlinedIcon fontSize="small" />,
            permissionKey: "sales",
            children: [
              {
                title: t("sales.quotation.index"),
                path: "/sales/quotation",
                permissionKey: "quotation",
              },
              {
                title: t("sales.order.index"),
                path: "/sales/order",
                permissionKey: "sales_order",
              },
              {
                title: t("sales.cash_sales.index"),
                path: "/sales/cash-sales",
                permissionKey: "cash_sales",
              },
              {
                title: t("sales.invoice.index"),
                path: "/sales/invoice",
                permissionKey: "sales_invoice",
              },
              {
                title: t("sales.return.index"),
                path: "/sales/return",
                permissionKey: "sales_return",
              },
              {
                title: t("sales.customer_deposit.index"),
                path: "/sales/customer-deposit",
                permissionKey: "customer_deposit",
              },
              {
                title: t("report"),
                path: "/sales/report",
                permissionKey: "sales_report",
              },
            ],
          },
        ],
      },
      {
        title: t("marketing.index"),
        items: [
          {
            title: t("marketing.index"),
            path: "/marketing",
            icon: <CampaignIcon fontSize="small" />,
            permissionKey: "marketing",
            children: [
              {
                title: t("marketing.promotion.index"),
                path: "/marketing/promotion",
                permissionKey: "promotion",
              },
            ],
          },
        ],
      },
      {
        title: t("logistic.index"),
        items: [
          {
            title: t("logistic.index"),
            path: "/logistic",
            icon: <LocalShippingOutlinedIcon fontSize="small" />,
            permissionKey: "logistic",
            children: [
              {
                title: t("logistic.delivery_order.index"),
                path: "/logistic/delivery-order",
                permissionKey: "delivery_order",
              },
              // {
              //   title: t("report"),
              //   path: "/logistic/report",
              //   permissionKey: "logistic_report",
              // },
            ],
          },
        ],
      },
      {
        title: t("purchase.index"),
        items: [
          {
            title: t("purchase.index"),
            path: "/purchase",
            icon: <AddShoppingCartOutlinedIcon fontSize="small" />,
            permissionKey: "purchase",
            children: [
              {
                title: t("purchase.request.index"),
                path: "/purchase/request",
                permissionKey: "purchase_request",
              },
              {
                title: t("purchase.order.index"),
                path: "/purchase/order",
                permissionKey: "purchase_order",
              },
              {
                title: t("purchase.return.index"),
                path: "/purchase/return",
                permissionKey: "purchase_return",
              },
              {
                title: t("report"),
                path: "/purchase/report",
                permissionKey: "purchase_report",
              },
            ],
          },
        ],
      },
      {
        title: t("user.index"),
        items: [
          {
            title: t("user.index"),
            path: "/user",
            icon: <PeopleOutlineOutlinedIcon fontSize="small" />,
            permissionKey: "user",
            children: [
              {
                title: t("user.account.index"),
                path: "/user/account",
                permissionKey: "user_account",
              },
              {
                title: t("user.role.index"),
                path: "/user/role",
                permissionKey: "role",
              },
              {
                title: t("user.setting.index"),
                path: "/user/setting?tab=branch",
                permissionKey: "user_setting",
              },
            ],
          },
        ],
      },
      {
        title: t("setting.index"),
        items: [
          {
            title: t("setting.index"),
            path: "/setting",
            icon: <SettingsOutlinedIcon fontSize="small" />,
            permissionKey: "setting",
            children: [
              {
                title: t("setting.business.index"),
                path: "/setting/business",
                permissionKey: "business",
              },
              {
                title: t("setting.approval.index"),
                path: "/setting/approval",
                permissionKey: "approval",
              },
              {
                title: t("setting.contact.index"),
                path: "/setting/contact",
                permissionKey: "contact_setting",
              },
              {
                title: t("setting.inventory.index"),
                path: "/setting/inventory",
                permissionKey: "inventory_setting",
              },
              {
                title: t("setting.sales.index"),
                path: "/setting/sales",
                permissionKey: "sales_setting",
              },
              {
                title: t("setting.employee.index"),
                path: "/setting/employee",
                permissionKey: "employee",
              },
              {
                title: t("setting.logistic.index"),
                path: "/setting/logistic",
                permissionKey: "logistic_setting",
              },
            ],
          },
        ],
      },
    ];

    return sections.reduce<any[]>((acc, section) => {
      const filteredItems = section.items
        .map((item: any) => {
          // if (!hasPermission(item.permissionKey, permissions)) return null;

          // const filteredChildren = item.children?.filter((child: any) =>
          //   hasPermission(child.permissionKey, permissions)
          // );

          return {
            ...item,
            children: item.children,
          };
        })
        .filter(Boolean); // Remove null entries

      if (filteredItems.length > 0) {
        acc.push({
          ...section,
          items: filteredItems,
        });
      }

      return acc;
    }, []);
  }, [permissions, t]);

  const navItemNavigateHandle = (path: string) => {
    navigate(path);
    if (isSmallScreen) {
      navbar?.sidebar.setIsSidebarOpen(false);
    }
  };

  const content = (
    <CustomizedScrollbar
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100dvh",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              pt: 2,
              px: 3,
            }}
          >
            <Link to={"/"}>
              <img
                style={{ cursor: "pointer", height: 90 }}
                src="/static/logo.jpg"
                alt="logo"
                onClick={() => navigate("/")}
              />
            </Link>
            <IconButton
              sx={{
                position: "absolute",
                right: 16,
                height: "auto",
                color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) =>
                  alpha(theme.palette.primary.main, 0.1),
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.2),
                },
              }}
              onClick={() => navbar?.sidebar.setIsSidebarOpen(false)}
            >
              <MenuIcon fontSize="small" />
            </IconButton>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ flexGrow: 1 }}>
            {filteredSections.map((section: any) => (
              <Fragment key={section.title}>
                {/* {section.title === t("user.index") && (
                  <Divider variant="middle" />
                )} */}
                <LeftNavbarSection
                  {...section}
                  isSmallScreen={isSmallScreen}
                  key={section.title}
                  path={pathname}
                  onItemClick={navItemNavigateHandle}
                  sx={{
                    p: 0,
                    "&:first-of-type": {
                      pt: 1,
                      mt: 1,
                    },
                    // "&:nth-of-type(8)": {
                    //   mt: 1,
                    // },
                  }}
                />
                {/* {section.title === t("setting.index") && (
                  <Divider variant="middle" />
                )} */}
              </Fragment>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "100%", py: 3 }}>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Application version {packageJson.version}
          </Typography>
          <Typography align="center" sx={{ opacity: 0.52, fontSize: 14 }}>
            Copyright by Soft Asset Co., Ltd.
          </Typography>
        </Box>
      </Box>
    </CustomizedScrollbar>
  );
  return (
    <Drawer
      open={navbar?.sidebar.isSidebarOpen}
      onClose={() => navbar?.sidebar.setIsSidebarOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          borderRightColor: "divider",
          borderRightStyle: "solid",
          borderRightWidth: 1,
          width: 260,
          zIndex: 2,
        },
      }}
      variant={isSmallScreen ? "temporary" : "persistent"}
    >
      {content}
    </Drawer>
  );
};

export default LeftNavbar;
