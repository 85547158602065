import { useEffect, useState } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import CustomizedStatus from "../../../Custom/CustomizedStatus";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
};

const useCurrencySettingColumnDefs = (
  onEditHandler: Props["onEditHandler"],
  onDeleteHandler: Props["onDeleteHandler"]
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("setting.unique_id"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        hide: true,
      },
      {
        field: "code",
        headerName: "รหัสสกุลเงิน",
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        sort: "asc",
      },
      {
        field: "name",
        headerName: "ชื่อสกุลเงิน",
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "symbol",
        headerName: "สัญลักษณ์",
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "status",
        headerName: "สถานะ",
        filter: "agSetColumnFilter",
        flex: 1,
        minWidth: 200,
        sort: "asc",
        filterParams: {
          values: ["active", "in_active"],
          valueFormatter: ({ value }: { value: string }) =>
            value === "active" ? "ใช้งาน" : "หยุดใช้งาน",
        },
        cellRenderer: ({ value }: { value: string }) => {
          return (
            <CustomizedStatus
              status={value === "active" ? "active" : "in_active"}
            />
          );
        },
        cellStyle: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      {
        field: "",
        filter: false,
        sortable: false,
        width: 100,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (["THB"].includes(params.data.code)) return <></>;
          else if (params.data) {
            return (
              <Box>
                <IconButton onClick={() => onEditHandler(params.data?.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDeleteHandler(params.data?.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            );
          }
        },
      },
    ]);
  }, [onDeleteHandler, onEditHandler, t]);

  return columnDefs;
};

export default useCurrencySettingColumnDefs;
