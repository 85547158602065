import { useEffect, useState } from "react";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { IPermissionAccess } from "../../../../types/Auth/role";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  salesSettingPermission?: IPermissionAccess;
};

export const usePaymentChannelColumnDefs = (
  onEditHandler: Props["onEditHandler"],
  onDeleteHandler: Props["onDeleteHandler"],
  salesSettingPermission?: IPermissionAccess
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  useEffect(() => {
    setColumnDefs([
      {
        field: "unique_id",
        headerName: t("setting.unique_id"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
        sort: "asc",
      },
      {
        field: "payment_name",
        headerName: t("setting.sales.payment_channel.payment_name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "name",
        headerName: t("setting.sales.payment_channel.name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "bank_name",
        headerName: t("setting.sales.payment_channel.bank_name"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "account_no",
        headerName: t("setting.sales.payment_channel.account_no"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "account_type",
        headerName: t("setting.sales.payment_channel.account_type"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "description",
        headerName: t("setting.description"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "payment_type",
        headerName: t("setting.sales.payment_channel.payment_type"),
        filter: "agSetColumnFilter",
        flex: 1,
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) => {
          if (params.value) {
            if (Array.isArray(params.value)) {
              return params.value
                .map((value: string) =>
                  t(`setting.sales.payment_channel.${value}`)
                )
                .join(", ");
            } else {
              if (params.value === "is_salable") {
                return t("setting.sales.payment_channel.is_salable");
              } else {
                return t("setting.sales.payment_channel.is_purchasable");
              }
            }
          }
          return "";
        },
      },
      {
        field: "",
        filter: false,
        sortable: false,
        width: 100,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data) {
            return (
              <Box>
                {salesSettingPermission?.update && (
                  <IconButton onClick={() => onEditHandler(params.data?.id)}>
                    <EditIcon />
                  </IconButton>
                )}
                {salesSettingPermission?.delete && (
                  <IconButton onClick={() => onDeleteHandler(params.data?.id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            );
          }
        },
      },
    ]);
  }, [onDeleteHandler, onEditHandler, t]);
  return columnDefs;
};
