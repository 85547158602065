import { Typography, Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IBreadcrumbsAndMenu } from "../../../../types/global";
import CustomizedButton from "../../../../components/Custom/CustomizedButton";
import CustomizedBreadcrumbs from "../../../../components/Custom/CustomizedBreadcrumbs";
import WarehouseTable from "../../../../components/Table/Setting/Warehouse";
import { useNavigate } from "react-router-dom";
import { useWarehousesFindAllQuery } from "../../../../generated/general";
import { createGraphQLClientWithMiddleware } from "../../../../services/graphqlClient";
import { useStateContext } from "../../../../contexts/auth-context";

const WarehouseSetting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    state: { permissions },
  } = useStateContext();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.inventory.index"),
      to: "/setting/inventory",
    },
    {
      name: t("setting.inventory.warehouse.index"),
    },
  ];

  const graphQLClient = createGraphQLClientWithMiddleware("general");

  const { data, isLoading } = useWarehousesFindAllQuery(graphQLClient);

  if (isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100dvh - 176px)",
          marginRight: "260px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">
          {t("setting.inventory.warehouse.index")}
        </Typography>
        {permissions?.inventory_setting?.create && (
          <CustomizedButton
            title={t("button.add") + t("setting.inventory.warehouse.index")}
            variant="contained"
            onClick={() => navigate("/setting/inventory/warehouse/add")}
          />
        )}
      </Box>
      <WarehouseTable data={data?.WarehousesFindAll || []} />
    </>
  );
};

export default WarehouseSetting;
