import { useRef } from "react";
import useAttributeColumnDefs from "./useAttributeColumnDefs";
import AgGrid from "../../../UI/AgGrid";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  data: any[];
  tableType?: string;
};

const AttributeTable = ({
  onEditHandler,
  onDeleteHandler,
  data,
  tableType,
}: Props) => {
  const gridRef = useRef(null);
  const {
    state: { permissions },
  } = useStateContext();

  const attributeColumnDefs = useAttributeColumnDefs(
    onEditHandler,
    onDeleteHandler,
    tableType ? permissions?.[tableType] : undefined
  );

  return (
    <AgGrid
      columnDefs={attributeColumnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
    />
  );
};

export default AttributeTable;
