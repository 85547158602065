import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import CustomizedButton from "../../Custom/CustomizedButton";
import CheckedUserModal from "../../UI/Modal/CheckedUserModal";
import { useEffect, useRef, useState } from "react";
import { useModal } from "../../../hooks/use-modal";
import { IUser } from "../../../types/Auth/user";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IApproval } from "../../../types/global";
import AgGrid from "../../UI/AgGrid";
import { useReviewerColumn } from "../../../hooks/use-reviewer-column";
import { useDisable } from "../../../hooks/use-disable";

type Props = {
  index: number;
  removeApprovalTemplateHandler: (index: number) => void;
};

const ApprovalTemplate = ({ index, removeApprovalTemplateHandler }: Props) => {
  const { control } = useFormContext<IApproval>();
  const gridRef = useRef();
  const [userIds, setUserIds] = useState<string[]>([]);
  const [userIdsSnapshot, setUserIdsSnapshot] = useState<string[]>([]);
  const [disabled] = useDisable();

  const { modal, openModalHandler, closeModalHandler } = useModal();

  const { fields, replace, remove } = useFieldArray({
    control,
    name: `approval_templates.${index}.approver_list` as `approval_templates.0.approver_list`,
    keyName: "genId",
  });

  const removeReviwerHandler = (index: number | null) => {
    remove(index || 0);
  };

  const columnDefs = useReviewerColumn(removeReviwerHandler);

  const finishUsersSelect = (data: IUser[]) => {
    // filter out users not selected in current modal session
    let filteredUsers = fields.filter((user: IUser) =>
      userIds.includes(user.unique_id)
    );

    // get current users id to prevent duplication when appending newly selected users
    const filteredUsersId = filteredUsers.map((user: IUser) => user.unique_id);

    data.forEach((user) => {
      if (!filteredUsersId.includes(user.unique_id)) {
        filteredUsers.push(user);
      }
    });

    // sort for proerly render order (and for role assignment when submitting)
    filteredUsers = filteredUsers.sort((a, b) => {
      return userIds.indexOf(a.unique_id) - userIds.indexOf(b.unique_id);
    });

    const formatUser: any[] = filteredUsers.map((user) => ({
      id: user.id,
      unique_id: user.unique_id,
      first_name: user.first_name,
      img_url: user.img_url,
      last_name: user.last_name,
      position: user.position,
      department: user.department,
      email: user.email,
      branch_name: user.branch_name,
    }));
    replace(formatUser);
    closeModalHandler();
  };

  useEffect(() => {
    const userUniqueId = fields.map((field) => field.unique_id);
    setUserIds(userUniqueId);
    setUserIdsSnapshot(userUniqueId);
  }, [fields]);

  return (
    <Box my={2}>
      <Accordion sx={{ mt: 3 }} defaultExpanded elevation={1}>
        <AccordionSummary
          sx={{ flexDirection: "row-reverse" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{`ลำดับที่ ${index + 1}`}</Typography>
            {!disabled && index !== 0 && (
              <IconButton
                aria-label="delete"
                onClick={() => removeApprovalTemplateHandler(index)}
                color="primary"
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: "0.5px solid #BEBEBE" }}>
          {!disabled && (
            <CustomizedButton
              sx={{ my: 2 }}
              title={"เพิ่มผู้มีสิทธิ์อนุมัติ"}
              variant="outlined"
              color="secondary"
              onClick={openModalHandler}
            />
          )}
          <AgGrid
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={fields}
            height={300}
            // suppressMenu
            disabledSidebar
            // suppressContextMenu
            // isClientSide
          />
        </AccordionDetails>
      </Accordion>
      <CheckedUserModal
        userIds={userIds}
        setUserIds={setUserIds}
        userIdsSnapshot={userIdsSnapshot}
        setUserIdsSnapshot={setUserIdsSnapshot}
        showSelectUser={modal}
        closeUserTable={closeModalHandler}
        finishUsersSelect={finishUsersSelect}
        rowSelection="multiple"
      />
    </Box>
  );
};

export default ApprovalTemplate;
