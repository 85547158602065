import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import ControlledTextField from "../../Controller/ControlledTextField";
import CustomizedButton from "../../Custom/CustomizedButton";
import ModalUI from "./ModalUI";
import ControlledSwitch from "../../Controller/ControlledSwitch";

type Props = {
  open: boolean;
  closeModalHandler: () => void;
  onAddHandler: (data: any, onSuccess: () => void) => void;
  onUpdateHandler: (data: any, onSuccess: () => void) => void;
  data?: any;
  isLoading?: boolean;
  isSuccess?: boolean;
};

const globalSettingSchema = {
  code: "",
  name: "",
  symbol: "",
  is_active: true,
};

const globalSettingValidate = Yup.object().shape({
  code: Yup.string().required("กรุณาระบุ"),
  name: Yup.string().required("กรุณาระบุ"),
  is_active: Yup.boolean(),
});

const CurrencyModal = ({
  open,
  closeModalHandler,
  onAddHandler,
  onUpdateHandler,
  data,
  isLoading,
  isSuccess,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const id = searchParams.get("id");
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm({
    defaultValues: globalSettingSchema,
    resolver: yupResolver(globalSettingValidate),
  });

  const onClose = () => {
    reset(globalSettingSchema);
    closeModalHandler();
  };

  useEffect(() => {
    if (isSuccess) {
      reset(data);
    }
  }, [data, isSuccess, reset]);

  return (
    <ModalUI
      isLoading={Boolean(id && isLoading)}
      title={
        id
          ? `${t("sentence.edit")}${t("setting.currency.index")}`
          : `${t("button.add")}${t("setting.currency.index")}`
      }
      open={open}
      handleClose={onClose}
      action={
        <Box sx={{ display: "flex", gap: 1 }}>
          <CustomizedButton
            title={t("button.cancel")}
            variant="outlined"
            onClick={onClose}
          />
          {id ? (
            <CustomizedButton
              title={t("button.save")}
              variant="contained"
              onClick={() => {
                handleSubmit((data) => {
                  onUpdateHandler(data, () => {
                    reset(globalSettingSchema);
                  });
                })();
              }}
            />
          ) : (
            <CustomizedButton
              title={t("button.add")}
              variant="contained"
              onClick={() => {
                handleSubmit((data) =>
                  onAddHandler(data, () => reset(globalSettingSchema))
                )();
              }}
            />
          )}
        </Box>
      }
    >
      <Typography color="primary.main" fontWeight={600} mb={2} fontSize={14}>
        ข้อมูล
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledTextField
            label={"รหัสสกุลเงิน"}
            control={control}
            name="code"
            error={Boolean(errors?.code)}
            helperText={errors?.code && errors.code.message?.toString()}
            inputProps={{
              maxLength: 3,
              style: { textTransform: "uppercase" },
            }}
            onKeyDown={(event) => {
              const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
              if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={"ชื่อสกุลเงิน"}
            control={control}
            name="name"
            error={Boolean(errors?.name)}
            helperText={errors?.name && errors.name.message?.toString()}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            label={"สัญลักษณ์"}
            control={control}
            name="symbol"
          />
        </Grid>
      </Grid>
      <Typography
        color="primary.main"
        fontWeight={600}
        fontSize={14}
        mt={3}
        mb={2}
      >
        สถานะ
      </Typography>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ControlledSwitch
            control={control}
            name="is_active"
            label={t("branch.status")}
          />
        </Grid>
      </Grid>
    </ModalUI>
  );
};

export default CurrencyModal;
