import { Typography } from "@mui/material";
import { CustomizedBox } from "../../Custom/CustomizedBox";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IApproval } from "../../../types/global";
import CustomizedButton from "../../Custom/CustomizedButton";
import ApprovalTemplate from "./ApprovalTemplate";
import { useSearchParams } from "react-router-dom";
import { useDisable } from "../../../hooks/use-disable";

const ApprovalList = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<IApproval>();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [disabled] = useDisable();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "approval_templates",
  });

  const addApprovalTemplateHandler = () => {
    append({
      workflow_document_type: tab ?? "",
      workflow_step_number: 1,
      required_approval: 1,
      approver_list: [],
    });
  };

  const removeApprovalTemplateHandler = (index: number) => {
    remove(index);
  };

  return (
    <CustomizedBox>
      <Typography fontWeight={"bold"} color={"primary.main"} mb={2}>
        {t("setting.approval.step")}
      </Typography>
      {!disabled && (
        <CustomizedButton
          variant="outlined"
          title={t("button.add") + t("setting.approval.step")}
          onClick={addApprovalTemplateHandler}
          disabled={fields.length === 3}
        />
      )}
      {fields.map((template, index) => (
        <ApprovalTemplate
          key={template.id}
          index={index}
          removeApprovalTemplateHandler={removeApprovalTemplateHandler}
        />
      ))}
    </CustomizedBox>
  );
};

export default ApprovalList;
