import { useRef } from "react";
import useGlobalSettingColumnDefs from "./useGlobalSettingColumnDefs";
import AgGrid from "../../../UI/AgGrid";
import { useStateContext } from "../../../../contexts/auth-context";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  tableType?: string;
  data: any[];
};

const GlobalSettingTable = ({
  onEditHandler,
  onDeleteHandler,
  data,
  tableType,
}: Props) => {
  const gridRef = useRef(null);
  const {
    state: { permissions },
  } = useStateContext();
  const columnDefs = useGlobalSettingColumnDefs(
    onEditHandler,
    onDeleteHandler,
    tableType ? permissions?.[tableType] : undefined
  );

  return (
    <AgGrid
      columnDefs={columnDefs}
      ref={gridRef}
      rowData={data || []}
      height={650}
      disabledSidebar
    />
  );
};

export default GlobalSettingTable;
