import { useEffect, useState } from "react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { IPermissionAccess } from "../../../../types/Auth/role";

type Props = {
  onEditHandler: (id?: number) => void;
  onDeleteHandler: (id?: number) => void;
  modulePermission: IPermissionAccess;
};

const useSetAttributeColumnDefs = (
  onEditHandler: Props["onEditHandler"],
  onDeleteHandler: Props["onDeleteHandler"],
  modulePermission?: Props["modulePermission"]
) => {
  const { t } = useTranslation();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "name",
        headerName: t("inventory.items.attribute"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "short_name",
        headerName: t("inventory.attribute.acronym"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "type",
        headerName: t("inventory.attribute.attributeType"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "description",
        headerName: t("setting.description"),
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "",
        filter: false,
        sortable: false,
        width: 100,
        cellStyle: {
          display: "flex",
          justifyContent: "center",
        },
        cellRenderer: (params: ICellRendererParams) => {
          if (params.data) {
            return (
              <Box>
                {modulePermission?.update && (
                  <IconButton onClick={() => onEditHandler(params.data?.id)}>
                    <EditIcon />
                  </IconButton>
                )}
                {modulePermission?.delete && (
                  <IconButton onClick={() => onDeleteHandler(params.data?.id)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            );
          }
        },
      },
    ]);
  }, [onDeleteHandler, onEditHandler, modulePermission, t]);

  return columnDefs;
};

export default useSetAttributeColumnDefs;
