import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface Props {
  status?: "active" | "inactive" | string | null;
  text?: string;
  fontSize?: string;
}

const StyledStatus = styled(Box)<BoxProps>(({ bgcolor }) => ({
  "&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: bgcolor,
    padding: ".25rem 0",
    minWidth: 95,
  },
}));

const StyledTypography = styled(Typography)<TypographyProps>(
  ({ color, fontSize }) => ({
    "&": {
      variant: "button",
      color: color,
      fontSize: fontSize ?? ".875rem",
    },
  })
);

const CustomizedStatus = ({ status, text, fontSize }: Props) => {
  const { t } = useTranslation();
  switch (status) {
    case "pre_sales":
    case "ร่าง":
    case "draft":
      return (
        <StyledStatus bgcolor="#F2F2F2">
          <StyledTypography color="#737373" fontSize={fontSize}>
            {text ?? t("status.draft")}
          </StyledTypography>
        </StyledStatus>
      );
    case "active":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {text ?? t("status.active")}
          </StyledTypography>
        </StyledStatus>
      );
    case "is_active":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            รอสแกน
          </StyledTypography>
        </StyledStatus>
      );
    case "used":
      return (
        <StyledStatus bgcolor="#F2F2F2">
          <StyledTypography color="#737373ß" fontSize={fontSize}>
            {text ?? t("status.coupon.used")}
          </StyledTypography>
        </StyledStatus>
      );
    case "in_transit":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {t("status.in_transit")}
          </StyledTypography>
        </StyledStatus>
      );
    case "is_scan":
    case "is_scanned":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            สแกนแล้ว
          </StyledTypography>
        </StyledStatus>
      );
    case "post_sales":
    case "รอโอนย้าย":
    case "wait_transfer":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {text ?? t("status.wait_transfer")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_receive":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {text ?? t("status.wait_receive")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_issue":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {text ?? t("status.wait_issue")}
          </StyledTypography>
        </StyledStatus>
      );
    case "issued":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#E58000" fontSize={fontSize}>
            {text ?? t("status.issued")}
          </StyledTypography>
        </StyledStatus>
      );
    case "เสร็จสิ้น":
    case "finished":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {text ?? t("status.finished")}
          </StyledTypography>
        </StyledStatus>
      );
    case "inactive":
      return (
        <StyledStatus bgcolor="#FFA396">
          <StyledTypography color="#B54839" fontSize={fontSize}>
            {text ?? t("status.in_active")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ยกเลิก":
    case "cancelled":
      return (
        <StyledStatus bgcolor="#BDBDBD">
          <StyledTypography color="#FFFFFF" fontSize={fontSize}>
            {text ?? t("status.cancelled")}
          </StyledTypography>
        </StyledStatus>
      );
    case "in_active":
      return (
        <StyledStatus bgcolor="#BDBDBD">
          <StyledTypography color="#FFFFFF" fontSize={fontSize}>
            {text ?? t("status.in_active")}
          </StyledTypography>
        </StyledStatus>
      );
    case "late":
      return (
        <StyledStatus bgcolor="#FFF2EB">
          <StyledTypography color="#CC6200" fontSize={fontSize}>
            {t("status.late")}
          </StyledTypography>
        </StyledStatus>
      );
    case "support":
    case "รออนุมัติ":
    case "wait_approve":
    case "pending":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {text ?? t("status.wait_approve")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอตอบรับ":
    case "wait_accept":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {t("status.wait_accept")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ตอบรับแล้ว":
    case "accepted":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {t("status.accepted")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ไม่อนุมัติ":
    case "not_approved":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {t("status.not_approved")}
          </StyledTypography>
        </StyledStatus>
      );
    case "อนุมัติแล้ว":
    case "approved":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("status.approved")}
          </StyledTypography>
        </StyledStatus>
      );
    case "delivering":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {t("status.delivering")}
          </StyledTypography>
        </StyledStatus>
      );
    case "delivery_success":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {t("status.delivery_success")}
          </StyledTypography>
        </StyledStatus>
      );
    case "delivery_fail":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {t("status.delivery_fail")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_payment":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {t("status.wait_payment")}
          </StyledTypography>
        </StyledStatus>
      );
    case "partially_payment":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {t("status.partially_payment")}
          </StyledTypography>
        </StyledStatus>
      );
    case "fully_payment":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {text ?? t("status.fully_payment")}
          </StyledTypography>
        </StyledStatus>
      );
    case "is_main_bom":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {t("status.is_main_bom")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอผลิต":
    case "pending_manu":
      return (
        <StyledStatus bgcolor="#FFF1C5">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {t("status.pending_manu")}
          </StyledTypography>
        </StyledStatus>
      );
    case "in_progress":
    case "กำลังผลิต":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography
            color="#1F5BB2"
            fontSize={fontSize}
          ></StyledTypography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้วบางส่วน":
    case "partially_ordered":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("status.partially_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้ว":
    case "fully_ordered":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("status.fully_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "นำเข้าแล้วบางส่วน":
    case "partially_imported":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("status.partially_imported")}
          </StyledTypography>
        </StyledStatus>
      );
    case "นำเข้าแล้ว":
    case "fully_imported":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("status.fully_imported")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_deduction":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {t("status.wait_deduction")}
          </StyledTypography>
        </StyledStatus>
      );
    case "partially_deduction":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {t("status.partially_deduction")}
          </StyledTypography>
        </StyledStatus>
      );
    default:
      return null;
  }
};

export default CustomizedStatus;
