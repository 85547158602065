import { Box, Typography } from "@mui/material";
import { GraphQLClient } from "graphql-request";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CustomizedBreadcrumbs from "../../../components/Custom/CustomizedBreadcrumbs";
import CustomizedButton from "../../../components/Custom/CustomizedButton";
import CurrencySettingTable from "../../../components/Table/Setting/Currency";
import ConfirmationModal from "../../../components/UI/Modal/ConfirmationModal";
import CurrencyModal from "../../../components/UI/Modal/CurrencyModal";
import { useStateContext } from "../../../contexts/auth-context";
import {
  CurrencyFineUniqueQuery,
  CurrencyViewAggridQuery,
  useCurrencyCreateMutation,
  useCurrencyFineUniqueQuery,
  useCurrencyRemoveMutation,
  useCurrencyUpdateMutation,
  useCurrencyViewAggridQuery,
} from "../../../generated/general";
import { useModal } from "../../../hooks/use-modal";
import { createGraphQLClientWithMiddleware } from "../../../services/graphqlClient";
import { IBreadcrumbsAndMenu } from "../../../types/global";

const CurrencySetting = () => {
  const {
    state: { authUser },
  } = useStateContext();

  const { t } = useTranslation();
  const [deletingId, setDeletingId] = useState<number>(0);
  let [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const setSearchParamsHandler = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };

  const removeSearchParamsHandler = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const { modal, openModalHandler, closeModalHandler } = useModal(
    undefined,
    () => removeSearchParamsHandler("id")
  );

  const { enqueueSnackbar } = useSnackbar();

  const breadcrumbs: IBreadcrumbsAndMenu[] = [
    {
      name: t("setting.index"),
      to: "/setting",
    },
    {
      name: t("setting.business.index"),
      to: "/setting/business",
    },
    {
      name: t("setting.currency.index"),
    },
  ];

  const graphQLClientGeneral: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data: rowData, refetch } =
    useCurrencyViewAggridQuery<CurrencyViewAggridQuery>(graphQLClientGeneral);

  const { data, isLoading, isSuccess } =
    useCurrencyFineUniqueQuery<CurrencyFineUniqueQuery>(
      graphQLClientGeneral,
      {
        currencyFineUniqueId: id ? parseInt(id) : 0,
      },
      {
        enabled: Boolean(id),
      }
    );

  const { mutateAsync: createCurrency } =
    useCurrencyCreateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: updateCurrency } =
    useCurrencyUpdateMutation<Error>(graphQLClientGeneral);

  const { mutateAsync: deleteCurrency } =
    useCurrencyRemoveMutation<Error>(graphQLClientGeneral);

  const onAddHandler = async (data: any, onSuccess: () => void) => {
    try {
      const dataInput = {
        ...data,
        name: data.name.trim(),
        code: data.code.toUpperCase(),
        created_by: {
          user_unique_id: authUser?.unique_id,
          email: authUser?.email,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
        },
      };
      await createCurrency({ createCurrencyInput: dataInput });
      enqueueSnackbar(`เพิ่ม${t("setting.currency.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
      closeModalHandler();
      onSuccess();
    } catch (err) {
      const errorMessage = (err as any)?.response?.errors?.[0]?.message;
      let formattedErrorMessage = "";

      switch (errorMessage) {
        case "can not create or update currency code": {
          formattedErrorMessage = `มีรหัสสกุลเงินอยู่ในระบบแล้ว กรุณาระบุใหม่`;
          break;
        }
        case "can not create or update currency name": {
          formattedErrorMessage = `มีชื่อสกุลเงินอยู่ในระบบแล้ว กรุณาระบุใหม่`;
          break;
        }
        default:
          formattedErrorMessage = `แก้ไข${t(
            "setting.currency.index"
          )}ไม่สำเร็จ`;
      }

      enqueueSnackbar(formattedErrorMessage, {
        variant: "error",
      });
    }
  };

  const onEditHandler = (id?: number) => {
    if (id) {
      setSearchParamsHandler("id", id.toString());
      openModalHandler();
    }
  };

  const onUpdateHandler = async (data: any, onSuccess: () => void) => {
    try {
      const { status, ...rest } = data;
      const dataInput = {
        ...rest,
        name: rest.name.trim(),
        code: rest.code.toUpperCase(),
        last_updated_by: {
          user_unique_id: authUser?.unique_id,
          email: authUser?.email,
          first_name: authUser?.first_name,
          last_name: authUser?.last_name,
          img_url: authUser?.img_url,
        },
      };

      await updateCurrency({
        updateCurrencyInput: dataInput,
      });
      enqueueSnackbar(`แก้ไข${t("setting.currency.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
      closeModalHandler();
      onSuccess();
    } catch (err) {
      const errorMessage = (err as any)?.response?.errors?.[0]?.message;
      let formattedErrorMessage = "";

      switch (errorMessage) {
        case "can not create or update currency code": {
          formattedErrorMessage = `มีรหัสสกุลเงินอยู่ในระบบแล้ว กรุณาระบุใหม่`;
          break;
        }
        case "can not create or update currency name": {
          formattedErrorMessage = `มีชื่อสกุลเงินอยู่ในระบบแล้ว กรุณาระบุใหม่`;
          break;
        }
        default:
          formattedErrorMessage = `แก้ไข${t(
            "setting.currency.index"
          )}ไม่สำเร็จ`;
      }

      enqueueSnackbar(formattedErrorMessage, {
        variant: "error",
      });
    }
  };

  const onDeleteHandler = (id?: number) => {
    if (id) {
      setDeletingId(id);
      openConfirmationHandler();
    }
  };

  const deleteActionHandler = async () => {
    try {
      await deleteCurrency({
        currencyRemoveId: deletingId,
      });
      setDeletingId(0);
      enqueueSnackbar(`ลบ${t("setting.currency.index")}สำเร็จ`, {
        variant: "success",
      });
      await refetch();
    } catch (err) {
      enqueueSnackbar(`ลบ${t("setting.currency.index")}ไม่สำเร็จ`, {
        variant: "error",
      });
    }
  };

  const {
    modal: confirmation,
    openModalHandler: openConfirmationHandler,
    closeModalHandler: closeConfirmationHandler,
    submitModalHandler: submitConfirmationHandler,
  } = useModal(deleteActionHandler);

  return (
    <>
      <CustomizedBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box sx={{ display: "flex", justifyContent: "space-between", my: 3 }}>
        <Typography variant="h5">{t("setting.currency.index")}</Typography>
        <CustomizedButton
          title={t("button.add") + t("setting.currency.index")}
          variant="contained"
          onClick={openModalHandler}
        />
      </Box>
      <CurrencySettingTable
        data={rowData?.CurrencyViewAggrid.results || []}
        onEditHandler={onEditHandler}
        onDeleteHandler={onDeleteHandler}
      />
      <CurrencyModal
        open={modal}
        closeModalHandler={closeModalHandler}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
        data={data?.CurrencyFineUnique}
        isLoading={isLoading}
        isSuccess={isSuccess}
      />
      <ConfirmationModal
        title={`ยืนยันการลบ${t("setting.currency.index")}`}
        open={confirmation}
        handleClose={closeConfirmationHandler}
        action={submitConfirmationHandler}
      />
    </>
  );
};

export default CurrencySetting;
