import { Navigate, Outlet, useLocation } from "react-router-dom";

type Props = {
  isAllow: boolean | undefined;
};

const RequirePermission = ({ isAllow }: Props) => {
  const location = useLocation();

  return isAllow ? (
    <Outlet />
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};
export default RequirePermission;
