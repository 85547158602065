import "./App.css";
import "./i18n/i18n";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/UI/Dashboard/Dashboard";
import Landing from "./pages/Landing";
import User from "./pages/User";
import UserAccount from "./pages/User/Account";
import UserAccountContainer from "./pages/User/Account/Container";
import Setting from "./pages/Setting";
import EmployeeSetting from "./pages/Setting/Employee";
import DepartmentSetting from "./pages/Setting/Employee/DepartmentSetting";
import PositionSetting from "./pages/Setting/Employee/PositionSetting";
import ContactSetting from "./pages/Setting/Contact";
import VendorTypeSetting from "./pages/Setting/Contact/VendorTypeSetting";
import CustomerTypeSetting from "./pages/Setting/Contact/CustomerTypeSetting";
import CustomerGroupSetting from "./pages/Setting/Contact/CustomerGroupSetting";
import VendorGroupSetting from "./pages/Setting/Contact/VendorGroupSetting";
import SalesSetting from "./pages/Setting/Sales";
import SalesTypeSetting from "./pages/Setting/Sales/SalesTypeSetting";
import SalesChannelSetting from "./pages/Setting/Sales/SalesChannelSetting";
import PaymentChannelSetting from "./pages/Setting/Sales/PaymentChannelSetting";
import InventorySetting from "./pages/Setting/Inventory";
import GroupCategorySetting from "./pages/Setting/Inventory/GroupCategorySetting";
import CategorySetting from "./pages/Setting/Inventory/CategorySetting";
import SubCategorySetting from "./pages/Setting/Inventory/SubCategorySetting";
import BrandSetting from "./pages/Setting/Inventory/BrandSetting";
import SegmentSetting from "./pages/Setting/Inventory/SegmentSetting";
import SeriesSetting from "./pages/Setting/Inventory/SeriesSetting";
import WarehouseSetting from "./pages/Setting/Inventory/Warehouse/WarehouseSetting";
import UomSetting from "./pages/Setting/Inventory/UomSetting";
import ModelSetting from "./pages/Setting/Inventory/ModelSetting";
import LocationSetting from "./pages/Setting/Inventory/LocationSetting";
import Contact from "./pages/Contact";
import Customer from "./pages/Contact/Customer";
import Vendor from "./pages/Contact/Vendor";
import CustomerContainer from "./pages/Contact/Customer/Container";
import VendorContainer from "./pages/Contact/Vendor/Container";
import CompanySetting from "./pages/Setting/Company";
import { Cookies } from "react-cookie";
import Login from "./pages/Login";
import UserSettingContainer from "./pages/User/Setting";
import BranchSetting from "./pages/Setting/Branch";
import BranchContainer from "./pages/Setting/Branch/BranchContainer";
import CustomerAttributeSetting from "./pages/Setting/Contact/CustomerAttributeSetting";
import VendorAttributeSetting from "./pages/Setting/Contact/VendorAttributeSetting";
import Inventory from "./pages/Inventory";
import Item from "./pages/Inventory/Item";
import ItemContainer from "./pages/Inventory/Item/Container";
import ZoneSetting from "./pages/Setting/Zone";
import SetAttributeSetting from "./pages/Setting/Inventory/SetAttributeSetting";
import AttributeSetting from "./pages/Setting/Inventory/AttributeSetting";
import GoodsReceive from "./pages/Inventory/GoodsReceive";
import GoodsReceiveContainer from "./pages/Inventory/GoodsReceive/Container";
import WarehouseContainer from "./pages/Setting/Inventory/Warehouse/Container";
import GoodsIssue from "./pages/Inventory/GoodsIssue";
import GoodsIssueContainer from "./pages/Inventory/GoodsIssue/Container";
import SelectBranch from "./pages/SelectBranch";
import GoodsReturn from "./pages/Inventory/GoodsReturn";
import GoodsReturnContainer from "./pages/Inventory/GoodsReturn/Container";
import GoodsTransfer from "./pages/Inventory/GoodsTransfer";
import GoodsTransferContainer from "./pages/Inventory/GoodsTransfer/Container";
import GoodsAdjustment from "./pages/Inventory/GoodsAdjustment";
import GoodsAdjustmentContainer from "./pages/Inventory/GoodsAdjustment/Container";
import InventoryReport from "./pages/Inventory/Report";
import Marketing from "./pages/Marketing";
import Promotion from "./pages/Marketing/Promotion";
import PromotionContainer from "./pages/Marketing/Promotion/Container";
import DeliveryOrder from "./pages/Logistic/DeliveryOrder";
import DeliveryOrderContainer from "./pages/Logistic/DeliveryOrder/Container";
import Purchase from "./pages/Purchase";
import PurchaseRequest from "./pages/Purchase/Request";
import PurchaseOrder from "./pages/Purchase/Order";
import PurchaseReturn from "./pages/Purchase/Return";
import PurchaseRequestContainer from "./pages/Purchase/Request/Container";
import PurchaseOrderContainer from "./pages/Purchase/Order/Container";
import PurchaseReturnContainer from "./pages/Purchase/Return/Container";
import PurchaseReport from "./pages/Purchase/Report";
import LogisticReport from "./pages/Logistic/Report";
import Logistic from "./pages/Logistic";
import CashSales from "./pages/Sales/CashSales";
import CashSalesContainer from "./pages/Sales/CashSales/Container";
import Sales from "./pages/Sales";
import Quotation from "./pages/Sales/Quotation";
import QuotationContainer from "./pages/Sales/Quotation/Container";
import SalesOrder from "./pages/Sales/Order";
import SalesOrderContainer from "./pages/Sales/Order/Container";
import SalesInvoice from "./pages/Sales/Invoice";
import SalesInvoiceContainer from "./pages/Sales/Invoice/Container";
import SalesReturn from "./pages/Sales/Return";
import SalesReturnContainer from "./pages/Sales/Return/Container";
import CustomerDeposit from "./pages/Sales/CustomerDeposit";
import CustomerDepositContainer from "./pages/Sales/CustomerDeposit/Container";
import SalesReport from "./pages/Sales/Report";
import SalesBySalesItemReport from "./pages/Sales/Report/SalesBySalesItemReport";
import SalesByCustomerReport from "./pages/Sales/Report/SalesByCustomerReport";
import SalesByBranchReport from "./pages/Sales/Report/SalesByBranchReport";
import CurrentStockByLocationReport from "./pages/Inventory/Report/CurrentStockByLocationReport";
import ItemImporter from "./pages/Inventory/Importer/Item";
import PriceList from "./pages/Inventory/PriceList";
import PriceListContainer from "./pages/Inventory/PriceList/Container";
import CustomerImporter from "./pages/Contact/Importer/Customer";
import VendorImporter from "./pages/Contact/Importer/Vendor";
import UserImporter from "./pages/User/Importer";
import ApprovalSetting from "./pages/Setting/Approval";
import InventoryApproval from "./pages/Setting/Approval/Inventory";
import GoodsTransferPdf from "./pages/Inventory/GoodsTransfer/Pdf";
import GoodsReturnPdf from "./pages/Inventory/GoodsReturn/Pdf";
import GoodsIssuePdf from "./pages/Inventory/GoodsIssue/Pdf";
import GoodsReceiptPdf from "./pages/Inventory/GoodsReceive/Pdf";
import PriceListImporter from "./pages/Inventory/Importer/PriceList";
import GoodsReceiveReport from "./pages/Inventory/Report/GoodsReceiveReport";
import GoodsReturnReport from "./pages/Inventory/Report/GoodsReturnReport";
import GoodsAdjustmentReport from "./pages/Inventory/Report/GoodsAdjustmentReport";
import GoodsTransferReport from "./pages/Inventory/Report/GoodsTransferReport";
import GoodsIssueReport from "./pages/Inventory/Report/GoodsIssueReport";
import UnscannedSNReport from "./pages/Inventory/Report/UnscannedSNReport";
import NoTraceEntryReport from "./pages/Inventory/Report/NoTraceEntryReport";
import TraceEntryReport from "./pages/Inventory/Report/TraceEntryReport";
import CurrentStockByBranchReport from "./pages/Inventory/Report/CurrentStockByBranchReport";
import CurrentStockByWarehouseReport from "./pages/Inventory/Report/CurrentStockByWarehouse";
import CurrentStockByLotReport from "./pages/Inventory/Report/CurrentStockByLotReport";
import CurrentStockBySNReport from "./pages/Inventory/Report/CurrentStockBySNReport";
import SalesApproval from "./pages/Setting/Approval/Sales";
import PurchaseApproval from "./pages/Setting/Approval/Purchase";
import QuotationPdf from "./pages/Sales/Quotation/Pdf";
import SalesOrderPdf from "./pages/Sales/Order/Pdf";
import CashSalesPdf from "./pages/Sales/CashSales/Pdf";
import PurchaseRequestPdf from "./pages/Purchase/Request/Pdf";
import CustomerDepositPdf from "./pages/Sales/CustomerDeposit/Pdf";
import SalesInvoicePdf from "./pages/Sales/Invoice/Pdf";
import SalesReturnPdf from "./pages/Sales/Return/Pdf";
import PurchaseOrderPdf from "./pages/Purchase/Order/Pdf";
import LogisticSetting from "./pages/Setting/Logistic";
import DeliveryTypeSetting from "./pages/Setting/Logistic/DeliveryTypeSetting";
import PurchaseOrderByVendorReport from "./pages/Purchase/Report/PurchaseOrderByVendorReport";
import PurchaseOrderByItemReport from "./pages/Purchase/Report/PurchaseOrderByItemReport";
import PurchaseOrderByBranchReport from "./pages/Purchase/Report/PurchaseOrderByBranchReport";
import ConsignmentPdf from "./pages/Inventory/GoodsTransfer/Pdf/ConsignmentPdf";
import Role from "./pages/User/Role";
import RoleContainer from "./pages/User/Role/Container";
import { useStateContext } from "./contexts/auth-context";
import RequirePermission from "./pages/RequirePermission";
import Unauthorized from "./pages/Unauthoried";
import DeliveryOrderPdf from "./pages/Logistic/DeliveryOrder/Pdf";
import CurrencySetting from "./pages/Setting/Currency";
import BusinessSetting from "./pages/Setting/Business";

const cookies = new Cookies();

function App() {
  const isLoggedIn = cookies.get("logged_in");
  const {
    state: { permissions },
  } = useStateContext();

  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn ? (
            <Navigate replace to="/landing" />
          ) : (
            <Navigate replace to="/login" />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/select-branch" element={<SelectBranch />} />
      <Route element={<DashboardLayout />}>
        <Route path="/landing" element={<Landing />} />
        {permissions && (
          <>
            <Route
              element={
                <RequirePermission isAllow={permissions?.contact?.view} />
              }
            >
              <Route path="/contact">
                <Route index element={<Contact />} />
                <Route
                  element={
                    <RequirePermission isAllow={permissions?.customer?.view} />
                  }
                >
                  <Route path="customer">
                    <Route index element={<Customer />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.customer?.create}
                        />
                      }
                    >
                      <Route path="add" element={<CustomerContainer />} />
                    </Route>
                    <Route path=":id" element={<CustomerContainer />} />
                    <Route path="import" element={<CustomerImporter />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission isAllow={permissions?.vendor?.view} />
                  }
                >
                  <Route path="vendor">
                    <Route index element={<Vendor />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.vendor?.create}
                        />
                      }
                    >
                      <Route path="add" element={<VendorContainer />} />
                    </Route>
                    <Route path=":id" element={<VendorContainer />} />
                    <Route path="import" element={<VendorImporter />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route
              element={
                <RequirePermission isAllow={permissions?.inventory?.view} />
              }
            >
              <Route path="/inventory">
                <Route index element={<Inventory />} />
                <Route
                  element={
                    <RequirePermission isAllow={permissions?.item?.view} />
                  }
                >
                  <Route path="items">
                    <Route index element={<Item />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.item?.create}
                        />
                      }
                    >
                      <Route path="add" element={<ItemContainer />} />
                    </Route>
                    <Route path=":id" element={<ItemContainer />} />
                    <Route path="import" element={<ItemImporter />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.goods_receive?.view}
                    />
                  }
                >
                  <Route path="goods-receive">
                    <Route index element={<GoodsReceive />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.goods_receive?.create}
                        />
                      }
                    >
                      <Route path="add" element={<GoodsReceiveContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<GoodsReceiveContainer />} />
                      <Route path="pdf" element={<GoodsReceiptPdf />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.goods_return?.view}
                    />
                  }
                >
                  <Route path="goods-return">
                    <Route index element={<GoodsReturn />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.goods_return?.create}
                        />
                      }
                    >
                      <Route path="add" element={<GoodsReturnContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<GoodsReturnContainer />} />
                      <Route path="pdf" element={<GoodsReturnPdf />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.goods_issue?.view}
                    />
                  }
                >
                  <Route path="goods-issue">
                    <Route index element={<GoodsIssue />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.goods_issue?.create}
                        />
                      }
                    >
                      <Route path="add" element={<GoodsIssueContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<GoodsIssueContainer />} />
                      <Route path="pdf" element={<GoodsIssuePdf />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.goods_transfer?.view}
                    />
                  }
                >
                  <Route path="goods-transfer">
                    <Route index element={<GoodsTransfer />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.goods_transfer?.create}
                        />
                      }
                    >
                      <Route path="add" element={<GoodsTransferContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<GoodsTransferContainer />} />
                      <Route path="pdf" element={<GoodsTransferPdf />} />
                      <Route
                        path="consignment-pdf"
                        element={<ConsignmentPdf />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.goods_adjust?.view}
                    />
                  }
                >
                  <Route path="goods-adjustment">
                    <Route index element={<GoodsAdjustment />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.goods_adjust?.create}
                        />
                      }
                    >
                      <Route
                        path="add"
                        element={<GoodsAdjustmentContainer />}
                      />
                    </Route>
                    <Route path=":id" element={<GoodsAdjustmentContainer />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.price_list?.view}
                    />
                  }
                >
                  <Route path="price-list">
                    <Route index element={<PriceList />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.price_list?.create}
                        />
                      }
                    >
                      <Route path="add" element={<PriceListContainer />} />
                    </Route>
                    <Route path=":id" element={<PriceListContainer />} />
                    <Route path="import" element={<PriceListImporter />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.inventory_report?.view}
                    />
                  }
                >
                  <Route path="report">
                    <Route index element={<InventoryReport />} />
                    <Route
                      path="goods-receive"
                      element={<GoodsReceiveReport />}
                    />
                    <Route
                      path="goods-return"
                      element={<GoodsReturnReport />}
                    />
                    <Route path="goods-issue" element={<GoodsIssueReport />} />
                    <Route
                      path="goods-transfer"
                      element={<GoodsTransferReport />}
                    />
                    <Route
                      path="goods-adjustment"
                      element={<GoodsAdjustmentReport />}
                    />
                    <Route
                      path="unscanned-sn"
                      element={<UnscannedSNReport />}
                    />
                    <Route
                      path="current-stock-by-sn"
                      element={<CurrentStockBySNReport />}
                    />
                    <Route
                      path="no-trace-entry"
                      element={<NoTraceEntryReport />}
                    />
                    <Route path="trace-entry" element={<TraceEntryReport />} />
                    <Route
                      path="current-stock-by-branch"
                      element={<CurrentStockByBranchReport />}
                    />
                    <Route
                      path="current-stock-by-warehouse"
                      element={<CurrentStockByWarehouseReport />}
                    />
                    <Route
                      path="current-stock-by-location"
                      element={<CurrentStockByLocationReport />}
                    />
                    <Route
                      path="current-stock-by-lot"
                      element={<CurrentStockByLotReport />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route
              element={<RequirePermission isAllow={permissions?.sales?.view} />}
            >
              <Route path="/sales">
                <Route index element={<Sales />} />
                <Route
                  element={
                    <RequirePermission
                      isAllow={
                        permissions?.quotation?.view ||
                        permissions?.quotation?.view_self
                      }
                    />
                  }
                >
                  <Route path="quotation">
                    <Route index element={<Quotation />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.quotation?.create}
                        />
                      }
                    >
                      <Route path="add" element={<QuotationContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<QuotationContainer />} />
                      <Route
                        path="pdf"
                        element={<QuotationPdf name="ใบเสนอราคา" />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={
                        permissions?.sales_order?.view ||
                        permissions?.sales_order?.view_self
                      }
                    />
                  }
                >
                  <Route path="order">
                    <Route index element={<SalesOrder />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.sales_order?.create}
                        />
                      }
                    >
                      <Route path="add" element={<SalesOrderContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<SalesOrderContainer />} />
                      <Route
                        path="pdf"
                        element={<SalesOrderPdf name="ใบสั่งขาย" />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.cash_sales?.view}
                    />
                  }
                >
                  <Route path="cash-sales">
                    <Route index element={<CashSales />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.cash_sales?.create}
                        />
                      }
                    >
                      <Route path="add" element={<CashSalesContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<CashSalesContainer />} />
                      <Route
                        path="tax-invoice-pdf"
                        element={
                          <CashSalesPdf
                            name={"ใบกำกับภาษี"}
                            variableName="tax_invoice"
                          />
                        }
                      />
                      <Route
                        path="receipt-pdf"
                        element={
                          <CashSalesPdf
                            name={"ใบเสร็จรับเงิน"}
                            variableName="receipt"
                          />
                        }
                      />
                      <Route
                        path="tax-invoice-receipt-pdf"
                        element={
                          <CashSalesPdf
                            name={"ใบเสร็จรับเงิน/ใบกำกับภาษี"}
                            variableName="receipt_tax_invoice"
                          />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.sales_invoice?.view}
                    />
                  }
                >
                  <Route path="invoice">
                    <Route index element={<SalesInvoice />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.sales_invoice?.create}
                        />
                      }
                    >
                      <Route path="add" element={<SalesInvoiceContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<SalesInvoiceContainer />} />
                      <Route
                        path="sales-invoice-pdf"
                        element={
                          <SalesInvoicePdf
                            name={"ใบแจ้งหนี้"}
                            variableName="sales_invoice"
                          />
                        }
                      />
                      <Route
                        path="tax-invoice-pdf"
                        element={
                          <SalesInvoicePdf
                            name={"ใบกำกับภาษี"}
                            variableName="tax_invoice"
                          />
                        }
                      />
                      <Route
                        path="delivery-tax-invoice-pdf"
                        element={
                          <SalesInvoicePdf
                            name={"ใบส่งของ/ใบกำกับภาษี"}
                            variableName="delivery_tax_invoice"
                          />
                        }
                      />
                      <Route
                        path="sales-invoice-tax-invoice-pdf"
                        element={
                          <SalesInvoicePdf
                            name={"ใบแจ้งหนี้/ใบกำกับภาษี"}
                            variableName="sales_invoice_tax_invoice"
                          />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.sales_return?.view}
                    />
                  }
                >
                  <Route path="return">
                    <Route index element={<SalesReturn />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.sales_return?.create}
                        />
                      }
                    >
                      <Route path="add" element={<SalesReturnContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<SalesReturnContainer />} />
                      <Route
                        path="sales-return-pdf"
                        element={<SalesReturnPdf name="ใบรับคืน" />}
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.customer_deposit?.view}
                    />
                  }
                >
                  <Route path="customer-deposit">
                    <Route index element={<CustomerDeposit />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.customer_deposit?.create}
                        />
                      }
                    >
                      <Route
                        path="add"
                        element={<CustomerDepositContainer />}
                      />
                    </Route>
                    <Route path=":id">
                      <Route index element={<CustomerDepositContainer />} />
                      <Route
                        path="customer-deposit-pdf"
                        element={
                          <CustomerDepositPdf
                            name="ใบมัดจำ"
                            variableName="customer_deposit"
                          />
                        }
                      />
                      <Route
                        path="tax-invoice-pdf"
                        element={
                          <CustomerDepositPdf
                            name="ใบกำกับภาษี"
                            variableName="tax_invoice"
                          />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.sales_report?.view}
                    />
                  }
                >
                  <Route path="report">
                    <Route index element={<SalesReport />} />
                    <Route
                      path="sales-by-item"
                      element={<SalesBySalesItemReport />}
                    />
                    <Route
                      path="sales-by-customer"
                      element={<SalesByCustomerReport />}
                    />
                    <Route
                      path="sales-by-branch"
                      element={<SalesByBranchReport />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route
              element={
                <RequirePermission isAllow={permissions?.purchase?.view} />
              }
            >
              <Route path="/purchase">
                <Route index element={<Purchase />} />
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.purchase_request?.view}
                    />
                  }
                >
                  <Route path="request">
                    <Route index element={<PurchaseRequest />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.purchase_request?.create}
                        />
                      }
                    >
                      <Route
                        path="add"
                        element={<PurchaseRequestContainer />}
                      />
                    </Route>
                    <Route path=":id">
                      <Route index element={<PurchaseRequestContainer />} />
                      <Route path="pdf" element={<PurchaseRequestPdf />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.purchase_order?.view}
                    />
                  }
                >
                  <Route path="order">
                    <Route index element={<PurchaseOrder />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.purchase_order?.create}
                        />
                      }
                    >
                      <Route path="add" element={<PurchaseOrderContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<PurchaseOrderContainer />} />
                      <Route path="pdf" element={<PurchaseOrderPdf />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.purchase_return?.view}
                    />
                  }
                >
                  <Route path="return">
                    <Route index element={<PurchaseReturn />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.purchase_return?.create}
                        />
                      }
                    >
                      <Route path="add" element={<PurchaseReturnContainer />} />
                    </Route>
                    <Route path=":id" element={<PurchaseReturnContainer />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.purchase_report?.view}
                    />
                  }
                >
                  <Route path="report">
                    <Route index element={<PurchaseReport />} />
                    <Route
                      path="purchase-order-by-vendor"
                      element={<PurchaseOrderByVendorReport />}
                    />
                    <Route
                      path="purchase-order-by-item"
                      element={<PurchaseOrderByItemReport />}
                    />
                    <Route
                      path="purchase-order-by-branch"
                      element={<PurchaseOrderByBranchReport />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="/user">
              <Route index element={<User />} />
              <Route
                element={
                  <RequirePermission
                    isAllow={permissions?.user_account?.view}
                  />
                }
              >
                <Route path="account">
                  <Route index element={<UserAccount />} />
                  <Route
                    element={
                      <RequirePermission
                        isAllow={permissions?.user_account?.create}
                      />
                    }
                  >
                    <Route path="add" element={<UserAccountContainer />} />
                  </Route>
                  <Route path=":id" element={<UserAccountContainer />} />
                  <Route path="import" element={<UserImporter />} />
                </Route>
              </Route>
              <Route
                element={
                  <RequirePermission isAllow={permissions?.role?.view} />
                }
              >
                <Route path="role">
                  <Route index element={<Role />} />
                  <Route
                    element={
                      <RequirePermission isAllow={permissions?.role?.create} />
                    }
                  >
                    <Route path="add" element={<RoleContainer />} />
                  </Route>
                  <Route path=":id" element={<RoleContainer />} />
                </Route>
              </Route>
              <Route path="setting" element={<UserSettingContainer />} />
            </Route>
            <Route
              element={
                <RequirePermission isAllow={permissions?.setting?.view} />
              }
            >
              <Route path="/setting">
                <Route index element={<Setting />} />
                <Route path="business">
                  <Route index element={<BusinessSetting />} />
                  <Route
                    element={
                      <RequirePermission isAllow={permissions?.zone?.view} />
                    }
                  >
                    <Route path="zone">
                      <Route index element={<ZoneSetting />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequirePermission isAllow={permissions?.company?.view} />
                    }
                  >
                    <Route path="company">
                      <Route index element={<CompanySetting />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequirePermission isAllow={permissions?.branch?.view} />
                    }
                  >
                    <Route path="branch">
                      <Route index element={<BranchSetting />} />
                      <Route
                        element={
                          <RequirePermission
                            isAllow={permissions?.branch?.create}
                          />
                        }
                      >
                        <Route path="add" element={<BranchContainer />} />
                      </Route>
                      <Route path=":id" element={<BranchContainer />} />
                    </Route>
                  </Route>
                  <Route>
                    <Route path="currencies">
                      <Route index element={<CurrencySetting />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission isAllow={permissions?.approval?.view} />
                  }
                >
                  <Route path="approval">
                    <Route index element={<ApprovalSetting />} />
                    <Route path="inventory" element={<InventoryApproval />} />
                    <Route path="sales" element={<SalesApproval />} />
                    <Route path="purchase" element={<PurchaseApproval />} />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.contact_setting?.view}
                    />
                  }
                >
                  <Route path="contact">
                    <Route index element={<ContactSetting />} />
                    <Route
                      path="customer-type"
                      element={<CustomerTypeSetting />}
                    />
                    <Route path="vendor-type" element={<VendorTypeSetting />} />
                    <Route
                      path="customer-group"
                      element={<CustomerGroupSetting />}
                    />
                    <Route
                      path="vendor-group"
                      element={<VendorGroupSetting />}
                    />
                    <Route
                      path="customer-attribute"
                      element={<CustomerAttributeSetting />}
                    />
                    <Route
                      path="vendor-attribute"
                      element={<VendorAttributeSetting />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.inventory_setting?.view}
                    />
                  }
                >
                  <Route path="inventory">
                    <Route index element={<InventorySetting />} />
                    <Route
                      path="group-category"
                      element={<GroupCategorySetting />}
                    />
                    <Route path="category" element={<CategorySetting />} />
                    <Route
                      path="sub-category"
                      element={<SubCategorySetting />}
                    />
                    <Route path="brand" element={<BrandSetting />} />
                    <Route path="segment" element={<SegmentSetting />} />
                    <Route path="series" element={<SeriesSetting />} />
                    <Route path="warehouse">
                      <Route index element={<WarehouseSetting />} />
                      <Route
                        element={
                          <RequirePermission
                            isAllow={permissions?.inventory_setting?.create}
                          />
                        }
                      >
                        <Route path="add" element={<WarehouseContainer />} />
                      </Route>
                      <Route path=":id" element={<WarehouseContainer />} />
                    </Route>
                    <Route path="location" element={<LocationSetting />} />
                    <Route path="uom" element={<UomSetting />} />
                    <Route path="model" element={<ModelSetting />} />
                    <Route path="attribute">
                      <Route index element={<SetAttributeSetting />} />
                      <Route path=":id" element={<AttributeSetting />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.logistic_setting?.view}
                    />
                  }
                >
                  <Route path="logistic">
                    <Route index element={<LogisticSetting />} />
                    <Route
                      path="delivery-type"
                      element={<DeliveryTypeSetting />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.sales_setting?.view}
                    />
                  }
                >
                  <Route path="sales">
                    <Route index element={<SalesSetting />} />
                    <Route path="type" element={<SalesTypeSetting />} />
                    <Route path="channel" element={<SalesChannelSetting />} />
                    <Route
                      path="payment-channel"
                      element={<PaymentChannelSetting />}
                    />
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission isAllow={permissions?.employee?.view} />
                  }
                >
                  <Route path="employee">
                    <Route index element={<EmployeeSetting />} />
                    <Route path="department" element={<DepartmentSetting />} />
                    <Route path="position" element={<PositionSetting />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route
              element={
                <RequirePermission isAllow={permissions?.marketing?.view} />
              }
            >
              <Route path="/marketing">
                <Route index element={<Marketing />} />
                <Route
                  element={
                    <RequirePermission isAllow={permissions?.promotion?.view} />
                  }
                >
                  <Route path="promotion">
                    <Route index element={<Promotion />} />
                    <Route path="discount-product">
                      <Route
                        element={
                          <RequirePermission
                            isAllow={permissions?.promotion?.create}
                          />
                        }
                      >
                        <Route
                          path="add"
                          element={
                            <PromotionContainer type="discount_product" />
                          }
                        />
                      </Route>
                      <Route
                        path=":id"
                        element={<PromotionContainer type="discount_product" />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route
              element={
                <RequirePermission isAllow={permissions?.logistic?.view} />
              }
            >
              <Route path="/logistic">
                <Route index element={<Logistic />} />
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.delivery_order?.view}
                    />
                  }
                >
                  <Route path="delivery-order">
                    <Route index element={<DeliveryOrder />} />
                    <Route
                      element={
                        <RequirePermission
                          isAllow={permissions?.delivery_order?.create}
                        />
                      }
                    >
                      <Route path="add" element={<DeliveryOrderContainer />} />
                    </Route>
                    <Route path=":id">
                      <Route index element={<DeliveryOrderContainer />} />
                      <Route path="pdf" element={<DeliveryOrderPdf />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={
                    <RequirePermission
                      isAllow={permissions?.logistic_report?.view}
                    />
                  }
                >
                  <Route path="report">
                    <Route index element={<LogisticReport />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </>
        )}
      </Route>
    </Routes>
  );
}

export default App;
